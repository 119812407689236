@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill {
  border-bottom: 2px solid rgba(156, 163, 175, var(--tw-border-opacity));
}


@keyframes circle-expand {
  0% {
    clip-path: circle(0% at 100% 0%);
  }
  100% {
    clip-path: circle(150% at 50% 50%);
  }
}

.circle-expand {
  animation: circle-expand 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@keyframes circle-collapse {
  0% {
    clip-path: circle(150% at 50% 50%);
  }
  100% {
    clip-path: circle(0% at 100% 0%);
  }
}

.circle-collapse {
  animation: circle-collapse 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.slide-top {
  animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-top {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100px);
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(-100px);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}

.slide-down {
  animation: slide-down 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

:root {
  --primary: #ec994b;
  --white: #ffffff;
  --bg: #f5f5f5;
}

.text-center {
  text-align: center;
}

.section-heading {
  font-size: 3rem;
  color: var(--primary);
  padding: 2rem 0;
}

#tranding {
  padding: 4rem 0;
}

#tranding .tranding-slider {
  height: 52rem;
  padding: 2rem 0;
  position: relative;
}

.tranding-slide {
  width: 100%; /* Mengisi lebar dari kontainer Swiper */
  max-width: 37rem; /* Maksimum lebar konten slide */
  position: relative;
}

.tranding-slide .tranding-slide-img img {
  width: 100%;
  height: auto; /* Mengatur tinggi agar gambar tetap proporsional */
  border-radius: 2rem;
  object-fit: cover;
}

/* Menyesuaikan posisi teks dan harga makanan */
.tranding-slide .tranding-slide-content {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  right: 2rem;
  color: var(--white);
}

.tranding-slide-content .food-price {
  position: absolute;
  top: 2rem;
  right: 2rem;
  color: var(--white);
}

.tranding-slide-content .tranding-slide-content-bottom {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  color: var(--white);
}

.food-rating {
  padding-top: 1rem;
  display: flex;
  gap: 1rem;
}

.rating ion-icon {
  color: var(--primary);
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.tranding-slider-control {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tranding-slider-control .slider-arrow {
  background: var(--white);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.tranding-slider-control .slider-arrow ion-icon {
  font-size: 2rem;
  color: #222224;
}

.tranding-slider-control .slider-arrow::after {
  content: '';
}

.tranding-slider-control .swiper-pagination {
  position: relative;
  width: 15rem;
  bottom: 1rem;
}

.tranding-slider-control .swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.tranding-slider-control .swiper-pagination .swiper-pagination-bullet-active {
  background: var(--primary);
}

.custom-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 1.5rem;
}

.swiper-button-prev,
.swiper-button-next {
  cursor: pointer;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  display: none;
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination-bullet {
  background: #000;
}


.custom-swiper-pagination .swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-button-next,
.swiper-button-prev {
  color: black !important;
}

.swiper-button-next {
  right: 10px; 
}

.swiper-button-prev {
  left: 10px;
}

